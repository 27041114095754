import React from "react" 
import styled from "styled-components";

// Section Component Imports
import Navigation from "../components/navigation/Navigation";
import Footer from "../components/Footer";

// Components
import {HeadingOne, HeadingTwo} from "./../components/Headings";
import Skepta from "./../components/wrappers/Skepta";
import {SquareFeatureBox} from "./../components/FeatureBoxes";
import {ExternalLinkButton} from "./../components/Buttons";
import {devices} from "./../components/devices";

import SEO from "../components/seo"

const IndexPage = () => (
  <>
    <SEO title="Join Pilot"/>
    <Navigation/>
    <SectionContainer>
        <Skepta >
            <HeadingOne>Join Pilot</HeadingOne>
            <p>Currently, we're working to consolidate our widgets platform, analytics dashboard and related tools into a comprehensive product for publishers that can be easily integrated with their news website. Simultaneously, we're looking for partners that need their data classified to pilot our data annotations platform with. Currently, we support image segmentation, image classification, handwriting recognition, but we're open to building widgets capable of supporting different types of tasks as well.</p>
            <HeadingTwo align={`center`}>What describes you best?</HeadingTwo>
            <FeatureBoxFlex>
                <SquareFeatureBox icon={`Typewriter`} title={`Publisher`} backgroundColor={`#fff`} height={`auto`}>
                    You're interested in using Turkbox to you monetize content on your digital news platform, online publication, or blog.
                    <ButtonSeparatorPara><ExternalLinkButton target="_blank" rel="noopener noreferrer" href={`https://tanay341739.typeform.com/to/ZwCIkfec`}>Sign Up</ExternalLinkButton></ButtonSeparatorPara>
                    </SquareFeatureBox>
                <SquareFeatureBox icon={`DataProvider`} title={`Data Provider`} backgroundColor={`#fff`} height={`auto`}>
                    You're interested in using Turkbox to annotate and label data using our image segmentation, classification and text transcription widgets.
                    <ButtonSeparatorPara><ExternalLinkButton target="_blank" rel="noopener noreferrer" href={`https://tanay341739.typeform.com/to/J7HZogfl`}>Sign Up</ExternalLinkButton></ButtonSeparatorPara>
                </SquareFeatureBox>
            </FeatureBoxFlex>
        </Skepta>
    </SectionContainer>
    <Footer/>
  </>
)

const SectionContainer = styled.section`
    background-color: #F4F4F0;
    width: 100%;
    padding: 90px 0 5% 0;

    @media ${devices.tablet} {
        padding: 10% 0 5% 0;
    }
`;

const FeatureBoxFlex = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;
    text-align: center;
`;

const ButtonSeparatorPara = styled.p`
    margin: 15px 0;
    text-align: center;
`;


export default IndexPage
